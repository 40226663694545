import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { trackers } from '@/configurations/trackers';
import { RolePermission } from '@/graphql';
import { usePermission } from '@/ui/modules/shared/hooks';
import { useAuth } from '@agentguru/auth';
import { useTranslation } from '@agentguru/i18n';
import { AppAgencyRoute } from '../components/AppAgencyRoute';
import { CockpitRoute } from '../components/CockpitRoute';
import { routes } from '../constants';
export function useBottomNavBarRoutes() {
    var t = useTranslation().t;
    var logout = useAuth().logout;
    var allowedBranches = usePermission([
        RolePermission.RolePermissionCreateAny,
    ]);
    var allowedAgencyCommission = usePermission([
        RolePermission.RolePermissionCreateAny,
    ]);
    var allowedServiceFee = usePermission([
        RolePermission.RolePermissionCreateAny,
    ]);
    var allowedMarkup = usePermission([RolePermission.RolePermissionCreateAny]);
    var allowedAirlineCommissions = usePermission([
        RolePermission.RolePermissionCreateAny,
    ]);
    var allowedSearchModules = usePermission([
        RolePermission.RolePermissionCreateAny,
    ]);
    var handleLogout = useCallback(function () {
        logout();
        trackers.destroy();
    }, [logout]);
    return [
        {
            key: 0,
            path: routes.goToAgencies(),
            icon: 'ag-agency',
            label: t('routes.agencies'),
            disabled: false,
            matches: [routes.agencies],
            isCustomRoute: false,
            subRoutes: [],
            show: true,
        },
        {
            key: 1,
            path: routes.goToBranches(),
            icon: 'ag-branch',
            label: t('routes.branches'),
            disabled: false,
            matches: [routes.branches],
            isCustomRoute: false,
            subRoutes: [],
            show: allowedBranches,
        },
        {
            key: 2,
            path: routes.goToCommissions(),
            icon: 'ag-commission',
            label: t('routes.agency_commission'),
            disabled: false,
            matches: [routes.commissions],
            isCustomRoute: false,
            subRoutes: [],
            show: allowedAgencyCommission,
        },
        {
            key: 3,
            path: routes.goToServiceFee(),
            icon: 'ag-payment',
            label: t('page.title.edit_service_fee'),
            disabled: false,
            matches: [routes.serviceFee],
            isCustomRoute: false,
            subRoutes: [],
            show: allowedServiceFee,
        },
        {
            key: 4,
            path: routes.goToMarkups(),
            icon: 'ag-markup',
            label: t('routes.markup'),
            disabled: false,
            matches: [routes.markup],
            isCustomRoute: false,
            subRoutes: [],
            show: allowedMarkup,
        },
        {
            key: 5,
            path: routes.goToAirlineCommissions(),
            icon: 'ag-airline-commissions',
            label: t('routes.airlines_commissions'),
            disabled: false,
            matches: [routes.airlinesCommissions],
            isCustomRoute: false,
            subRoutes: [],
            show: allowedAirlineCommissions,
        },
        {
            key: 6,
            path: routes.goToSearchModules(),
            icon: 'ag-search-module',
            label: t('routes.search'),
            disabled: false,
            matches: [routes.searchModules],
            isCustomRoute: false,
            subRoutes: [],
            show: allowedSearchModules,
        },
        {
            key: 7,
            icon: 'ag-profile',
            label: t('routes.profile'),
            disabled: false,
            path: '/',
            matches: [],
            isCustomRoute: true,
            show: true,
            subRoutes: [
                {
                    key: 0,
                    icon: '',
                    disabled: false,
                    path: '/',
                    label: 'AppAgencyRoute',
                    customRender: _jsx(AppAgencyRoute, {}),
                },
                {
                    key: 1,
                    icon: '',
                    disabled: false,
                    path: '/',
                    label: 'CockpitRoute',
                    customRender: _jsx(CockpitRoute, {}),
                },
                {
                    key: 2,
                    icon: 'ag-logout',
                    disabled: false,
                    path: '/',
                    label: t('routes.logout'),
                    callback: function () { return handleLogout(); },
                },
            ],
        },
    ].filter(function (route) { return route.show; });
}

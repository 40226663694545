import { jsx as _jsx } from "react/jsx-runtime";
import { createElement, memo } from 'react';
import { Navigate } from 'react-router-dom';
import { Shell } from '@/ui/layouts/Shell';
import { usePermission } from '@/ui/modules/shared/hooks';
import { withAuthenticationRequired } from '@agentguru/auth';
import { WaitModule } from '@agentguru/components';
function Route(_a) {
    var component = _a.component, _b = _a.permissions, permissions = _b === void 0 ? [] : _b;
    var allowedRoute = usePermission(permissions);
    if (!allowedRoute) {
        return _jsx(Navigate, { to: "/", replace: true });
    }
    return createElement(component);
}
export var RouteProtected = memo(Route);
var ShellWithAuthentication = withAuthenticationRequired(Shell, {
    onRedirecting: function () { return _jsx(WaitModule, {}); },
    returnTo: window.location.pathname + window.location.search,
});
export var ShellProtected = memo(ShellWithAuthentication);

import { RolePermission } from '@/graphql';
import { usePermission } from '@/ui/modules/shared/hooks';
import { useTranslation } from '@agentguru/i18n';
import { routes } from '../constants';
export function useNavBarRoutes() {
    var t = useTranslation().t;
    var allowedBranches = usePermission([
        RolePermission.RolePermissionCreateAny,
    ]);
    var allowedAgencyCommission = usePermission([
        RolePermission.RolePermissionCreateAny,
    ]);
    var allowedServiceFee = usePermission([
        RolePermission.RolePermissionCreateAny,
    ]);
    var allowedMarkup = usePermission([RolePermission.RolePermissionCreateAny]);
    var allowedAirlineCommissions = usePermission([
        RolePermission.RolePermissionCreateAny,
    ]);
    var allowedSearchModules = usePermission([
        RolePermission.RolePermissionCreateAny,
    ]);
    return [
        {
            key: 0,
            path: routes.goToAgencies(),
            icon: 'ag-agency',
            label: t('routes.agencies'),
            disabled: false,
            matches: ["/".concat(routes.agencies)],
            isCustomRoute: false,
            subRoutes: [],
            show: true,
        },
        {
            key: 1,
            path: routes.goToBranches(),
            icon: 'ag-branch',
            label: t('routes.branches'),
            disabled: false,
            matches: ["/".concat(routes.branches)],
            isCustomRoute: false,
            subRoutes: [],
            show: allowedBranches,
        },
        {
            key: 2,
            path: routes.goToCommissions(),
            icon: 'ag-commission',
            label: t('routes.agency_commission'),
            disabled: false,
            matches: ["/".concat(routes.commissions)],
            isCustomRoute: false,
            subRoutes: [],
            show: allowedAgencyCommission,
        },
        {
            key: 3,
            path: routes.goToServiceFee(),
            icon: 'ag-payment',
            label: t('page.title.edit_service_fee'),
            disabled: false,
            matches: ["/".concat(routes.serviceFee)],
            isCustomRoute: false,
            subRoutes: [],
            show: allowedServiceFee,
        },
        {
            key: 4,
            path: routes.goToMarkups(),
            icon: 'ag-markup',
            label: t('routes.markup'),
            disabled: false,
            matches: ["/".concat(routes.markup)],
            isCustomRoute: false,
            subRoutes: [],
            show: allowedMarkup,
        },
        {
            key: 5,
            path: routes.goToAirlineCommissions(),
            icon: 'ag-airline-commissions',
            label: t('routes.airlines_commissions'),
            disabled: false,
            matches: ["/".concat(routes.airlinesCommissions)],
            isCustomRoute: false,
            subRoutes: [],
            show: allowedAirlineCommissions,
        },
        {
            key: 6,
            path: routes.goToSearchModules(),
            icon: 'ag-search-module',
            label: t('routes.search'),
            disabled: false,
            matches: ["/".concat(routes.searchModules)],
            isCustomRoute: false,
            subRoutes: [],
            show: allowedSearchModules,
        },
    ].filter(function (route) { return route.show; });
}

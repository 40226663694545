import { ENV } from '@/configurations/env';
import i18n from '@agentguru/i18n';
import en_US from '../tolgee/translation/en-US.json';
import pt_BR from '../tolgee/translation/pt-BR.json';
i18n.addResourceBundle('en_US', 'translation', en_US);
i18n.addResourceBundle('pt_BR', 'translation', pt_BR);
i18n.tolgee.updateOptions({
    apiUrl: ENV.TOLGEE_API_URL,
    apiKey: ENV.TOLGEE_API_KEY,
});
